import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'lab-test',
    loadChildren: () => import('./pages/lab-test/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'product-list/:slug',
    loadChildren: () => import('./pages/products/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  // {
  //   path: 'product-list/search/:slug',
  //   loadChildren: () => import('./pages/globalproducts/product-list/product-list.module').then( m => m.ProductListPageModule)
  // },
  {
    path: 'product-details/:slug',
    loadChildren: () => import('./pages/products/product-details/product-details.module').then( m => m.ProductDetailsPageModule)
  },
  {
    path: 'report-list',
    loadChildren: () => import('./pages/reports/report-list/report-list.module').then( m => m.ReportListPageModule)
  },
  {
    path: 'report-details/:slug',
    loadChildren: () => import('./pages/reports/report-details/report-details.module').then( m => m.ReportDetailsPageModule)
  },
  {
    path: 'corporates',
    loadChildren: () => import('./pages/cms/corporates/corporates.module').then( m => m.CorporatesPageModule)
  },
  {
    path: 'lab-list',
    loadChildren: () => import('./pages/our-labs/lab-list/lab-list.module').then( m => m.LabListPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/cms/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'lab-booking-modal',
    loadChildren: () => import('./pages/our-labs/lab-booking-modal/lab-booking-modal.module').then( m => m.LabBookingModalPageModule)
  },
  {
    path: 'create-test-modal',
    loadChildren: () => import('./pages/reports/create-test-modal/create-test-modal.module').then( m => m.CreateTestModalPageModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./pages/cms/partners/partners.module').then( m => m.PartnersPageModule)
  },
  {
    path: 'partners-modal',
    loadChildren: () => import('./pages/cms/partners-modal/partners-modal.module').then( m => m.PartnersModalPageModule)
  },
  {
    path: 'add-report',
    loadChildren: () => import('./pages/reports/add-report/add-report.module').then( m => m.AddReportPageModule)
  },
  {
    path: 'lab-details/:slug',
    loadChildren: () => import('./pages/our-labs/lab-details/lab-details.module').then( m => m.LabDetailsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'add-address',
    loadChildren: () => import('./pages/add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'doctors',
    loadChildren: () => import('./pages/cms/doctors/doctors.module').then( m => m.DoctorsPageModule)
  },
  {
    path: 'filter-modal',
    loadChildren: () => import('./pages/filter-modal/filter-modal.module').then( m => m.FilterModalPageModule)
  },
  // {
  //   path: 'my-account',
  //   loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
  // },
  {
    path: 'edit-profile-modal',
    loadChildren: () => import('./pages/edit-profile-modal/edit-profile-modal.module').then( m => m.EditProfileModalPageModule)
  },
  {
    path: 'add-patient-modal',
    loadChildren: () => import('./pages/reports/add-patient-modal/add-patient-modal.module').then( m => m.AddPatientModalPageModule)
  },
  {
    path: 'add-report-modal',
    loadChildren: () => import('./pages/reports/add-report-modal/add-report-modal.module').then( m => m.AddReportModalPageModule)
  },
  {
    path: 'order-cancel',
    loadChildren: () => import('./pages/order-cancel/order-cancel.module').then( m => m.OrderCancelPageModule)
  },
  {
    path: 'order-details/:slug',
    loadChildren: () => import('./pages/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'help/:slug',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/cms/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'career',
    loadChildren: () => import('./pages/cms/career/career.module').then( m => m.CareerPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/cms/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'news-details/:slug',
    loadChildren: () => import('./pages/cms/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    path: 'career-apply',
    loadChildren: () => import('./pages/cms/career-apply/career-apply.module').then( m => m.CareerApplyPageModule)
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders/my-orders.module').then( m => m.MyOrdersPageModule)
  },
  {
    path: 'my-address',
    loadChildren: () => import('./pages/my-address/my-address.module').then( m => m.MyAddressPageModule)
  },
  {
    path: 'purchase-details/:slug',
    loadChildren: () => import('./pages/purchase-details/purchase-details.module').then( m => m.PurchaseDetailsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'my-patient',
    loadChildren: () => import('./pages/my-patient/my-patient.module').then( m => m.MyPatientPageModule)
  },
  {
    path: 'city-option-modal',
    loadChildren: () => import('./pages/city-option-modal/city-option-modal.module').then( m => m.CityOptionModalPageModule)
  },
  {
    path: 'lab-packages',
    loadChildren: () => import('./pages/lab-packages/lab-packages.module').then( m => m.LabPackagesPageModule)
  },
  {
    path: 'package-details/:slug',
    loadChildren: () => import('./pages/package-details/package-details.module').then( m => m.PackageDetailsPageModule)
  },  {
    path: 'lab-package-details',
    loadChildren: () => import('./pages/lab-package-details/lab-package-details.module').then( m => m.LabPackageDetailsPageModule)
  },
  {
    path: 'product-details-popup',
    loadChildren: () => import('./pages/products/product-details-popup/product-details-popup.module').then( m => m.ProductDetailsPopupPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
