import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  session_is_loggedin: boolean = false;
  session_logged_in_name: any;
  session_logged_in_email: any;
  customer_id: any;
  cartItemCount=0;
  cartTotalprice = 0;
  private logedinuser = new Subject<string>();

  constructor(
    public storage: Storage,
  ) { 
    this.get_all_Details();
  }

  /**obsetvable implement 
   * developer: chandan
   */

  public getuserLoggedin(): Observable<string> {
    return this.logedinuser.asObservable();
  }

  public setuserLoggedin(data) {
      this.logedinuser.next(data);
  }



  /**
   * this function is called to get user details
   * Developer: chandan
   */
  async get_all_Details(){
    await this.getObject("session_user").then((resu) => {
      const sess = JSON.parse(JSON.stringify(resu));
      console.log(sess);
      if(sess !== null){
        this.session_is_loggedin = true;
        this.session_logged_in_name = (sess.firstName ? sess.firstName : '')+' '+(sess.lastName ? sess.lastName : '')
        this.session_logged_in_email = sess.email ? sess.email : '';
        this.customer_id = sess.id;
      }else{
        this.session_is_loggedin = false;
        this.session_logged_in_name = null
        this.session_logged_in_email = null;
        this.customer_id = null;
      }
    });
  }

    

  /**
   * this function is called to set data in key value pair from storage
   * Developer: Debraj Chakraborty
   */
    async set(key: string, value: any): Promise<any> {
      try {
        const result = await this.storage.set(key, value);
        return true;
      } catch (error) {
          console.log(error);
          return false;
      }
    }
  
  
  
  
   /**
   * this function is called to get data in key value pair from storage
   * Developer: Debraj Chakraborty
   */
    async get(key: string): Promise<any> {
      try {
        const result = await this.storage.get(key);
        if (result != null) {
          return result;
        }
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    }



  /**
   * this function is called to set a object in key value pair
   * Developer: Debraj Chakraborty
   */
  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
      return true;
    } catch (error) {
        console.log(error);
        return false;
    }
  }




  /**
   * this function is called to get a object in key value pair 
   * Developer: Debraj Chakraborty
   */
  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        console.log(JSON.parse(result));
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
        console.log(reason);
        return null;
    }
  }




  /**
   * this function is called to remove session deatils form storage as well as storage reset 
   * Developer: chandan
   */
  async logout() {
    this.storage.ready();
    await this.storage.remove("session_user").then(async ()=>{
      await this.storage.clear();
      this.session_is_loggedin = false;
        this.session_logged_in_name = ''
        this.session_logged_in_email ='';
        this.customer_id = null;
        this.setuserLoggedin("user_not_login");
    });

  }
}
