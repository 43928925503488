import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServiceService } from './services/auth-service.service';
// import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { GoogleServicesService } from './services/google-services.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  session_is_loggedin: boolean = false;
  session_logged_in_name: any;
  session_logged_phone: any;
  customer_id: any;
  // public appPages = [
  //   {
  //     title: 'Inbox',
  //     url: '/folder/Inbox',
  //     icon: 'mail'
  //   },
  //   {
  //     title: 'Outbox',
  //     url: '/folder/Outbox',
  //     icon: 'paper-plane'
  //   }
  // ];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private authserv: AuthServiceService,
    private googleServicesService : GoogleServicesService
    // private ga: GoogleAnalytics

  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('android')) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#000000');
      }
    });
    // this.ga.startTrackerWithId('343837290')
    //   .then(() => {
    //     console.log('Google analytics is ready now');
    //     this.ga.trackView('Outbox')
    //       .then(() => {
    //       })
    //       .catch(
    //         error => console.log(error)
    //       );
    //   }).catch(
    //     error => console.log('Google Analytics Error: ' + error)
    //   );

    this.authserv.getuserLoggedin().subscribe(data => {
      if (data) {
        console.log("user log out successfully.")
        this.checkSession();
      }
    });
    this.checkSession();
    this.googleServicesService.startTrackerWithId('G-E0Y1C1FGTN');
  }

  goto(url) {
    this.router.navigateByUrl(url);
  }

  async checkSession() {
    await this.authserv.getObject("session_user").then((resu) => {
      const sess = JSON.parse(JSON.stringify(resu));
      console.log(sess);
      if (sess !== null) {
        this.session_is_loggedin = true;
        this.session_logged_in_name = sess.firstName ? sess.firstName : '';
        console.log(this.session_logged_in_name);
        this.session_logged_phone = sess.phone ? sess.phone : '';
        this.customer_id = sess.id;
      } else {
        this.session_is_loggedin = false;
        this.session_logged_in_name = null
        this.session_logged_phone = null;
        this.customer_id = null;
      }
    });
  }
  // trackEvent(val) {
  //   // Label and Value are optional, Value is numeric
  //   this.ga.trackEvent('Category', 'Action', 'Label', val)
  // }
  /**
   * this function called to logout
   * Developer: Debraj Chakraborty
   */
  async logout() {
    await this.authserv.logout();
    this.router.navigateByUrl("/home");
  }
}
